<template>
    <div>
        <h6 class="d-inline">Invoice Nr: </h6><strong>{{ invoice.invoice_nr }}</strong><br>
        <h6 class="d-inline">Company Name: </h6><strong>{{ invoice.company_name }}</strong><br>
        <h6 class="d-inline">Status: </h6><strong>{{ invoice.status_name }}</strong><br>
        <h6 class="d-inline">Invoice Date: </h6><strong>{{ invoice.invoice_date }}</strong><br>
        <h6 class="d-inline">Due Date: </h6><strong>{{ invoice.due_date }}</strong><br>
        <h6 class="d-inline">Sent Date: </h6><strong>{{ invoice.sent_date }}</strong><br>
        <h6 class="d-inline">Paid Date: </h6><strong>{{ invoice.paid_date }}</strong><br>
        <h6 class="d-inline">VAT Rate: </h6><strong>{{ invoice.vat_rate }}</strong><br>
        <h6 class="d-inline">Total EUR: </h6><strong>{{ invoice.total }}</strong><br>
        <h6 class="d-inline">Total VAT EUR: </h6><strong>{{ invoice.total_vat }}</strong><br>
        <h6 class="d-inline">Total with VAT EUR: </h6><strong>{{ invoice.total_with_vat }}</strong><br>
        <h6 class="d-inline">Billing Details: </h6><strong>{{ invoice.billing_details }}</strong><br>
        <h6 class="d-inline">Company Details: </h6><strong>{{ invoice.company_details }}</strong><br>
        <h6 class="d-inline">Created At: </h6><strong>{{ invoice.created_at }}</strong><br>
        <h6 class="d-inline">Updated At: </h6><strong>{{ invoice.updated_at }}</strong><br>
        <template v-if="deleted"><h6 class="d-inline">Deleted At: </h6><strong>{{ invoice.deleted_at }}</strong><br></template>
        <h6 class="d-inline">Invoice Details: </h6><strong>{{ invoice.invoice_details }}</strong><br>

        <router-link :to="{ name: 'EditInvoice', props: { id: invoice.id } }" class="btn btn-primary">edit</router-link>
        <button class="btn btn-primary" @click="showSendInvoice=true" :disabled="deleted">{{ invoice.sent_date ? 're-send' : 'send' }}</button>
        <button @click="showChangeStatus=true" class="btn btn-primary">change status</button>
        <button v-if="deleted" class="btn btn-success" @click="restoreInvoice">restore</button>
        <button v-else class="btn btn-primary" @click="deleteInvoice">delete</button>
        <a :href="invoice.pdf" target="_blank" class="btn btn-primary">open pdf</a>
    </div>

    <modal-dropdown-component v-if="showChangeStatus"
                              @hideComponent="showChangeStatus=false"
                              title="Select status"
                              :fields="invoiceStatuses"
                              @selected="invoiceStatusChanged"
    />

    <send-invoice-component v-if="showSendInvoice"
                            @closeModal="showSendInvoice=false"
                            :invoice="invoice"
    />
</template>

<script>
import {invoiceService} from "@/services/invoiceService";
import ModalDropdownComponent from "@/components/general/ModalDropdownComponent";
import SendInvoiceComponent from "@/components/invoices/SendInvoiceComponent";

export default {
    name: "ViewInvoice",
    data() {
        return {
            invoice: {},
            showChangeStatus: false,
            showSendInvoice: false,
        }
    },
    computed: {
        invoiceId() {
            return this.$route.params.id
        },
        invoiceStatuses() {
            return this.$store.getters.invoiceStatuses
        },
        deleted() {
            return this.invoice.deleted_at
        },
    },
    created() {
        this.getInvoice()
    },
    methods: {
        getInvoice() {
            invoiceService.getInvoice(this.invoiceId)
                .then(response => this.invoice = response.data.data)
        },
        invoiceStatusChanged(selected) {
            invoiceService.updateInvoiceStatus(this.invoiceId, selected.id)
                .then(() => this.invoice.status_name = selected.name)
        },
        deleteInvoice() {
            let areYouSure = confirm('Are you sure?')

            if (areYouSure) {
                invoiceService.deleteInvoice(this.invoiceId)
                    .then(response => {
                        this.invoice.deleted_at = response.data.deleted_at
                        this.invoice.status = 'deleted'
                    })
            }
        },
        restoreInvoice() {
            let areYouSure = confirm('Are you sure?')

            if (areYouSure) {
                invoiceService.restoreInvoice(this.invoiceId)
                    .then(() => {
                        delete this.invoice.deleted_at
                        this.invoice.status = 'created'
                    })
            }
        }
    },
    components: {
        ModalDropdownComponent,
        SendInvoiceComponent
    }
}
</script>

<style scoped>

</style>